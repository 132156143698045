import React, { Component } from "react";
import axios from "axios";
import styles from "./orders.css";

import { Carousel } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import "./orders.css"

import queryString from 'query-string';
class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preParedOrdersData: [],
      unPreparedOrdersData: [],
      backimage:undefined,
      globalReponseIp:'',
      prepCardColor:'',
      ipAddress:'',
      prepHeadingColor:'',
      underPrepHeadingColor:'',
      prepOrderNumColor:'',
      underPrepOrderNumColor:'',
      underPrepHeadingFontSize:'',
      PrepHeadingFontSize:'',
      underPrepcustomerNameColor:'',
      PrepcustomerNameColor:'',
      d3:[],
      d4:[]
    };
    
  }

   

  componentDidMount() {
    let search = window.location.search;
    // console.log("search============>",search)
    let params = new URLSearchParams(search);
    // console.log("params========>",params)
    let ipAddress = params.get('ip');
    // console.log("ip======>",ipAddress)
    this.setState({
      ipAddress:ipAddress
    })
    this.getPreparedOrders();
    this.interval = setInterval(() => {
        this.getPreparedOrders();
    }, 1000);
  }


  getPreparedOrders() {
    const {ipAddress} = this.state
    axios.get("http://localhost:8090/new ").then(res => {
      const res1 = res.data.Data
      const totalData = res.data.Total
      const res2 = JSON.parse(res1)
      const finalTotal = JSON.parse(totalData)
    this.setState({ d3: res2,d4:finalTotal})
    });
  }
  


  componentWillUnmount() {
    clearInterval(this.interval);
  }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             

  render() {
  const {d3,d4} = this.state
  const len = d3.output !== undefined ?d3.output.display.line[1].text:''
  const len2 = d4.output !== undefined ? d4.output.display.line[1].text:''
  const contentStyle = {
    height: '12.5em',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

    return (
      <div className="content">
        <div
          className="row"
          style={{
            margin: "0px 0px",
            backgroundColor: "#ffffff",
            height: "25vh"
          }}
        >
          <div className="col-lg-12">
            <p />
            <div
              className="row"
              style={{ backgroundColor: "#87CEEB", height: "9vh" }}
            >
              <div className="col-lg-6">
                <span
                  style={{ float: "left", fontSize: "2.5em", color: "white" }}
                >
                {d3.output!==undefined ? <span>{d3.output.display.line[0].text[0].content}</span>:''}
                </span>
              </div>
              <div className="col-lg-2">
                <span style={{ fontSize: "2.5em", color: "white" }}>
                {d3.output!==undefined ? <span>{d3.output.display.line[0].text[1].content}</span>:''}
                  {/* {d2.output.display.line[0].text[1].content} */}
                </span>
              </div>
              <div className="col-lg-2">
                <span style={{ fontSize: "2.5em", color: "white" }}>
                  {Array.isArray(len) === true? <span>{d3.output!==undefined ? d3.output.display.line[1].text[0].content:''}</span>:<span>{d3.output!==undefined ?d3.output.display.line[1].text.content:''}</span>}
                </span>
              </div>
              <div className="col-lg-2">
                <span style={{ fontSize: "2.5em", color: "white" }}>
                {Array.isArray(len) === true? <span>{d3.output!==undefined ?d3.output.display.line[1].text[1].content:''}</span>:<span>{d3.output!==undefined ?d3.output.display.line[1].text.content:''}</span>}
                  {/* {d2.output.display.line[1].text[1].content} */}
                </span>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-lg-6">
                <span style={{ float: "left", fontSize: "2.5em",color: "black" }}>TOTAL</span>
              </div>
              <div className="col-lg-2">
                <span style={{ fontSize: "2.5em",color: "black" }}>
                  {/* {d3.output!==undefined ?d3.output.display.line[0].text[1].content:''} */}
                </span>
              </div>
              <div className="col-lg-2" />
              <div className="col-lg-2">
                <span style={{ fontSize: "2.5em",color: "black" }}>
                  {/* {d2.output.display.line[1].text[1].content} */}
                  {Array.isArray(len2) === true?<span>{d4.output!==undefined ?d4.output.display.line[1].text[1].content:''}</span>:<span>{d4.output!==undefined ?d4.output.display.line[1].text.content:''}</span>}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            margin: "0px 0px",
            backgroundColor: "white",
            height: "75vh",
            borderTop: "10px solid black"
          }}
        >
          <div className="col-lg-12">
            <p />
            <div className="row">
              <div className="col-lg-12">
                <h1 style={{ fontWeight: "bold", fontSize: "4em" }}>
                  EVERY 1ST SUNDAY <br /> OF EACH MONTH
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Carousel autoplay>
                  <div>
                 <img style={contentStyle} src = "./images/DiscountStore.jpg" alt ="1"/>
                  </div>
                  <div>
                    <img style={contentStyle} src = "./images/4000Organic.jfif" alt ="2"/>
                  </div>
                  <div>
                  <img style={contentStyle} src = "./images/almondmilk.jfif" alt ="3"/>
                  </div>
                  <div>
                  <img style={contentStyle} src = "./images/OrganicPilots.jfif" alt ="4"/>
                  </div>
                  <div>
                  <img style={contentStyle} src = "./images/OrganicPiza.jfif" alt ="5"/>
                  </div>
                  <div>
                  <img style={contentStyle} src = "./images/PizzaDiscount.jfif" alt ="5"/>
                  </div>
                  <div>
                  <img style={contentStyle} src = "./images/Ramadan.jpg" alt ="6"/>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Orders;
