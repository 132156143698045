import React from 'react';
import Orders from '../src/components/orders.js'
import './App.css';

function App() {
  return (
    <div className="App">
    <Orders />
    </div>
  );
}

export default App;
